import React, { useState } from "react";
import hotel1 from "../Parvatiwoods/Hotel services/Brahmaputra-River-Rafting-Assam-31.jpg";
import hotel2 from "../Parvatiwoods/Hotel services/slider3.jpg";
import hotel3 from "../Parvatiwoods/Hotel services/trekking_banner.jpg";
import para from "../Parvatiwoods/Hotel services/paragliding.jpeg";
import camp from "../Parvatiwoods/Hotel services/camp.jpeg";
import "../styles/Services.css"; // Import CSS file for styling
import Footer from "./Footer";
import { Helmet } from "react-helmet";

const Services = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState({});

  const openPopup = (content) => {
    setPopupContent(content);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <Helmet>
        <title>best treaking experience of kheerganga</title>
        <meta
          name="title"
          content="best treaking experience of kheerganga"
        ></meta>
        <meta
          name="description"
          content="Finding an ideal family-friendly inn in Kasol does not have to be difficult. Welcome to Parvati Woods Cottage, a nice option for travellers like you.

    Parvati Woods Cottage offers guests an array of room amenities including a fireplace.
    
    The inn offers 24 hour front desk, 24 hour check-in, and room service, to make your visit even more pleasant. The property also features an on-site restaurant. Guests arriving by vehicle have access to free parking.
    
    While in Kasol be sure to experience local falafel favourites at Evergreen.
    
    Parvati Woods Cottage puts the best of Kasol at your fingertips, making your stay both relaxing and enjoyable."
        />

        <meta
          name="keywords"
          content="parvatiwoods cottage 97 | Parvati Woods Cottage | Family-Friendly Inn in Kasol | Fireplace & Amenities"
        />

        <link rel="canonical" href="https://parvatiwoods97.in/services" />
      </Helmet>
      <section className="property-slider">
        <div className="sliderp">
          <div className="slides">
            <div
              className="divcon"
              onClick={() =>
                openPopup({
                  title: "River Rafting",
                  description: (
                    <>
                      <p>
                        Rafting in Kasol is a must try adventurous activity and
                        a seren experience. Feeling the adrenaline rush while
                        paddling through the gurgling water and splashes of
                        piercing cold water is an amazing experience. The
                        rafting stretch is covered with the sight of gorgeous
                        landscapes, dense green valley view and clear blue sky.
                        There are two rafting stretches offered in Kasol, one is
                        of 8 km and the other is for 10 km.
                      </p>

                      <p>
                        The best time for river rafting is the winter season
                        because the water level of the river in the winter
                        season is very low as compared to the water level in the
                        summer season. Parvati River originates from ManTalai
                        Glacier is a high tide, roaring river makes rafting very
                        adventurous.
                      </p>
                      <p>
                        Parvati Valley is one of the most beautiful valleys in
                        the Himalayan Kingdom and enjoying the scenic views
                        amidst the hustle of rapids while rafting is a surreal
                        experience. Rafting in Kasol is fascinating, on one side
                        it gives the thrills and chills of the adventurous sport
                        and the other side it bestows tranquility.
                      </p>
                    </>
                  ),
                })
              }
            >
              <img src={hotel1} alt="River Rafting" />
              <div className="content">
                <h1>River Rafting</h1>
                <p>
                  Get Ready for an unforgettable adventure? River rafting offers
                  thrill and nature's beauty, whether a novice or experienced
                  adventurer.
                </p>
              </div>
            </div>
            <div
              className="divcon"
              onClick={() =>
                openPopup({
                  title: "Cab services",
                  description:
                    "Start or end your journey stress-free with our airport transfer services. Our professional drivers will ensure you reach your destination on time, every time.",
                })
              }
            >
              <img src={hotel2} alt="Cab Services" />
              <div className="content">
                <h1>Cab services</h1>
                <p>
                  Start or end your journey stress-free with our airport
                  transfer services. Our professional drivers will ensure you
                  reach your destination on time, every time.
                </p>
              </div>
            </div>
            <div
              className="divcon"
              onClick={() =>
                openPopup({
                  title: "Trekking",
                  description: (
                    <>
                      <p>
                        Reach parvatiwoods, the Kheerganga trek’s beginning site
                        (3050 Meters). Before departing parvatiwoods, you can
                        purchase additional food items if necessary by meeting
                        us here. From here, it takes 5 to 6 hours on foot to
                        travel the 12 kilometres to Kheerganga Top. The walk
                        begins on a gradual rise that is surrounded by lovely
                        apple orchards. As the walk goes on, deodar and oak
                        woodlands are also starting to appear often. The Parvati
                        River, which is plainly seen from our lodge, is close
                        and roars unceasingly. Once you arrive, We’ll provide
                        you a cosy tent to spend the night in.
                      </p>
                      <p>
                        Prior to going directly to the Parvati Kund or the
                        natural hot springs, which become even more alluring in
                        the winter, take some time to unwind at the camp. The
                        view of the snow-covered mountains, which is plain to
                        see from a distance. Before turning in for the night in
                        your tents, savour the lovely dinner that was prepared
                        for you. Cut the lights!
                      </p>
                    </>
                  ),
                })
              }
            >
              <img src={hotel3} alt="Trekking" />
              <div className="content">
                <h1>Trekking</h1>
                <p>
                  Welcome to Trekking Adventures! Explore unforgettable outdoor
                  experiences with treks for every expertise level, from
                  seasoned hikers to novices.
                </p>
              </div>
            </div>
            <div
              className="divcon"
              onClick={() =>
                openPopup({
                  title: "Trekking",
                  description:
                    "Welcome to Trekking Adventures! Explore unforgettable outdoor experiences with treks for every expertise level, from seasoned hikers to novices.",
                })
              }
            >
              <img src={para} alt="Paragliding" />
              <div className="content">
                <h1>Paragliding</h1>
                <p>
                  Experience the thrill of paragliding in Kasol with
                  Parvatiwoods Cottage. Nestled in the heart of Kasol, our hotel
                  offers exclusive packages for an unforgettable adventure in
                  the skies.
                </p>
              </div>
            </div>
            <div
              className="divcon"
              onClick={() =>
                openPopup({
                  title: "Camping",
                  description: (
                    <>
                      <p>
                        Camping in Kasol, located in the picturesque Parvati
                        Valley of Himachal Pradesh, India, offers a serene and
                        captivating experience for nature lovers and adventure
                        seekers alike. Known for its lush greenery, crystal
                        clear waters, and tranquil environment, Kasol serves as
                        a perfect escape from the hustle and bustle of city
                        life.
                      </p>
                    </>
                  ),
                })
              }
            >
              <img src={camp} alt="Camping" />
              <div className="content">
                <h1>Camping</h1>
                <p>
                  Camping is a form of outdoor recreation or outdoor education
                  involving overnight stay, so enjoy your memorable nights with
                  parvati woods and live the best nights of your life
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />

      {showPopup && (
        <div className="popup" onClick={closePopup}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={closePopup}>
              ×
            </span>
            <h2>{popupContent.title}</h2>
            <p>{popupContent.description}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default Services;
