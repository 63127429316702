// components/BookRoom.js
import React from "react";
import Footer from "./Footer";
import "../styles/BookRoom.css";
import home3 from "../Parvatiwoods/Hotel rooms/home3.jpg";
import { useState } from "react";
import { Helmet } from "react-helmet";

const BookRoom = () => {
  const [showModal, setShowModal] = useState(false);
  const [roomDetails, setRoomDetails] = useState({
    name: "",
    description: "",
    maxAdults: 0,
    size: "",
  });

  // Function to toggle the modal
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleMoreDetails = (details) => {
    setRoomDetails(details);
    toggleModal();
  };

  return (
    <>
      <Helmet>
        <title>best luxurious rooms of kasol</title>
        <meta name="title" content="best luxurious rooms of kasol"></meta>

        <meta
          name="description"
          content="Finding an ideal family-friendly inn in Kasol does not have to be difficult. Welcome to Parvati Woods Cottage, a nice option for travellers like you.

    Parvati Woods Cottage offers guests an array of room amenities including a fireplace.
    
    The inn offers 24 hour front desk, 24 hour check-in, and room service, to make your visit even more pleasant. The property also features an on-site restaurant. Guests arriving by vehicle have access to free parking.
    
    While in Kasol be sure to experience local falafel favourites at Evergreen.
    
    Parvati Woods Cottage puts the best of Kasol at your fingertips, making your stay both relaxing and enjoyable."
        />

        <meta
          name="keywords"
          content="parvatiwoods cottage 97 | Parvati Woods Cottage | Family-Friendly Inn in Kasol | Fireplace & Amenities"
        />

        <link rel="canonical" href="https://parvatiwoods97.in/book-room" />
      </Helmet>

      <section className="book-room">
        <h1>Parvati Woods Cottage 97</h1>
        <h3>Select Rooms</h3>

        <div className="box">
          <img src={home3} alt="first"></img>
          <div>
            <p>Standard room</p>
            <p>2 Adults max</p>
            <p>30 m^2</p>
          </div>
          <h3
            className="more-details"
            onClick={() =>
              handleMoreDetails({
                name: "Standard room",
                description: "Basic room with essential amenities.",
                maxAdults: 2,
                size: "30 m^2",
              })
            }
          >
            More details
          </h3>

          {
            //<p onClick={<StandardRoom></StandardRoom>}>More Details</p>
          }
        </div>

        <div className="box">
          <img src={home3} alt="second"></img>
          <div>
            <p>Superior room</p>
            <p>2 Adults max</p>
            <p>50 m^2</p>
          </div>
          <h3
            className="more-details"
            onClick={() =>
              handleMoreDetails({
                name: "Superior room",
                description: "Basic room with essential amenities.",
                maxAdults: 2,
                size: "50 m^2",
              })
            }
          >
            More details
          </h3>

          {
            //<p onClick={<StandardRoom></StandardRoom>}>More Details</p>
          }
        </div>

        <div className="box">
          <img src={home3} alt="third"></img>
          <div>
            <p>Family room</p>
            <p>3 Adults max</p>
            <p>70 m^2</p>
          </div>

          <h3
            className="more-details"
            onClick={() =>
              handleMoreDetails({
                name: "Family room",
                description: "Basic room with essential amenities.",
                maxAdults: 3,
                size: "70 m^2",
              })
            }
          >
            More details
          </h3>
          {
            //<p onClick={<StandardRoom></StandardRoom>}>More Details</p>
          }
        </div>

        {/* Modal for displaying room details */}
        {showModal && (
          <div className="modal-container">
            <div className="modal">
              <span className="close" onClick={toggleModal}>
                &times;
              </span>
              <h2>{roomDetails.name}</h2>
              <p>{roomDetails.description}</p>
              <p>Max Adults: {roomDetails.maxAdults}</p>
              <p>Size: {roomDetails.size}</p>
              <a href="/contact">
                <h3>Contact</h3>
              </a>
              {/* Amenities section */}
              <div className="Amenities">
                <h3>Amenities</h3>
                <ul>
                  <li>Wi-Fi</li>
                  <li>TV</li>
                  <li>Air conditioning</li>
                  {/* Add more amenities as needed */}
                </ul>
              </div>
            </div>
          </div>
        )}
      </section>

      <Footer></Footer>
    </>
  );
};

export default BookRoom;
