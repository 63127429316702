import React, { useState } from "react";
import "../styles/Reviews.css";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

const Reviews = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const reviews = [
    {
      id: 1,
      author: "Sahil Singh",
      email: "sahil@example.com",
      phone: "9820522698",
      text: "Great location, really pleasant and clean rooms, but the thing that makes this such a good place to stay are the staff. All of the people are incredibly helpful.",
    },
    {
      id: 2,
      author: "Rishab Sharma",
      email: "sharmarishav893@gmail.com",
      phone: "9920243276",
      text: "The stay was lovely, loved the breakfast which was included. The place was clean and very comfortable Enjoyed the stay.",
    },
    {
      id: 3,
      author: "Tejas Narnaware",
      email: "ntejas023@gmail.com",
      phone: "8356942642",
      text: "Very helpful and kind staff. Good service. Nice and clean accommodations. Highly recommendable hotel.",
    },
    {
      id: 4,
      author: "Ansham Sharma",
      email: "sharmaansham88@gmail.com",
      phone: "82195 37350",
      text: "I had a very pleasant stay. The staff was really helpful and courteous. The location of the hotel is great and close to everything. Regards.",
    },
    // Add more reviews as needed
  ];

  const goToSlide = (slideIndex) => {
    setCurrentSlide(slideIndex);
  };

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === reviews.length - 1 ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? reviews.length - 1 : prevSlide - 1
    );
  };

  return (
    <>
      <Helmet>
        <title>great vacation at kasol at parvatiwoods cottage 97</title>
        <meta
          name="title"
          content="great vacation at kasol at parvatiwoods cottage 97"
        ></meta>

        <meta
          name="description"
          content="Finding an ideal family-friendly inn in Kasol does not have to be difficult. Welcome to Parvati Woods Cottage, a nice option for travellers like you.

    Parvati Woods Cottage offers guests an array of room amenities including a fireplace.
    
    The inn offers 24 hour front desk, 24 hour check-in, and room service, to make your visit even more pleasant. The property also features an on-site restaurant. Guests arriving by vehicle have access to free parking.
    
    While in Kasol be sure to experience local falafel favourites at Evergreen.
    
    Parvati Woods Cottage puts the best of Kasol at your fingertips, making your stay both relaxing and enjoyable."
        />

        <meta
          name="keywords"
          content="parvatiwoods cottage 97 | Parvati Woods Cottage | Family-Friendly Inn in Kasol | Fireplace & Amenities"
        />

        <link rel="canonical" href="https://parvatiwoods97.in/reviews" />
      </Helmet>
      <section className="reviews">
        <div className="slider">
          {reviews.map((review, index) => (
            <div
              key={review.id}
              className={index === currentSlide ? "slide active" : "slide"}
            >
              <h2>{review.author}</h2>
              <p>{review.text}</p>
              <p>{review.email}</p>
              <p>{review.phone}</p>
            </div>
          ))}
          <button className="prev" onClick={prevSlide}>
            &#10094;
          </button>
          <button className="next" onClick={nextSlide}>
            &#10095;
          </button>
          <div className="bullets">
            {reviews.map((_, index) => (
              <span
                key={index}
                className={index === currentSlide ? "bullet active" : "bullet"}
                onClick={() => goToSlide(index)}
              ></span>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Reviews;
